.labelName {
    color: white !important;
    font-size: 16px;
}

.numberBtn {
    text-align: center !important;
}

.numberBtn button {
    width: 70px !important;
    font-size: 22px !important;
    height: 70px !important;
    background-color: #303844 !important;
    box-shadow: unset !important;
}

.ant-modal-centered {
    width: 100% !important;
}
checkInModal {
     position: fixed;
     top: 50%;
     bottom: 50%;

}
/* .checkInModal {
    text-align: center !important;
}

.checkInModal .ant-modal-content {
    padding: 0px !important;
}

.checkInModal .ant-modal-close {
    display: none !important;
}

.checkInModal .ant-modal-content .ant-modal-header {
    background-color: #F15733 !important;
}

.checkInModal .ant-modal-content .ant-modal-header .ant-modal-title {
    color: white !important;
    padding: 15px 25px !important;
    font-size: 18px !important;
}

.checkInModal .ant-modal-content .ant-modal-body {
    padding: 15px 25px !important;
}

.checkInModal .ant-modal-content .ant-modal-body p {
    font-size: 16px !important;
    font-weight: bold !important;
}

.checkInModal .ant-modal-content .ant-modal-body .like-btn {
    margin-top: 15px !important;
} */

.result input {
    height: 40px !important;
    margin: 8px 0px !important;
    border-radius: 10px !important;
    width: 100% !important;
}

.coloredBtn button {
    background-color: #F15733 !important;
    color: white !important;
    width: 100% !important;
    border-radius: 0 !important;
    box-shadow: unset !important;
    margin-top: 20px !important;
    height: 38px !important;
}

.borderBtn button {
    background-color: transparent !important;
    color: #F15733 !important;
    width: 100% !important;
    border-radius: 0 !important;
    box-shadow: unset !important;
    margin-top: 20px !important;
    height: 38px !important;
}

.textCenter {
    text-align: center !important;
}

.poppins_regular {
    letter-spacing: 0 !important;
}

.poppins_regular-18 {
    letter-spacing: 0 !important;
    font-size: 18px !important;
    font-weight: 400 !important;
}

.poppins_regular-20 {
    letter-spacing: 0 !important;
    font-size: 20px !important;
    font-weight: 400 !important;
}

.poppins_semibold-50 {
    letter-spacing: 0 !important;
    font-size: 50px !important;
    font-weight: 600 !important;
}

.poppins_semibold-50 {
    letter-spacing: 0 !important;
    font-size: 50px !important;
    font-weight: 600 !important;
}

.poppins_semibold-35 {
    letter-spacing: 0 !important;
    font-size: 35px !important;
    font-weight: 600 !important;
}

.poppins_medium-20 {
    letter-spacing: 0 !important;
    font-size: 20px !important;
    font-weight: 500 !important;
}

:where(.css-dev-only-do-not-override-7786z2).ant-row {
    display: flex !important;
    flex-flow: row wrap !important;
    min-width: 0 !important;
}

.greenBtn {
    text-align: center !important;
}

.greenBtn button {
    width: 70px !important;
    height: 70px !important;
    background-color: #87d068 !important;
    box-shadow: unset !important;
}


/* 
.calculator-body {
    max-width: 400px !important;
    margin: auto !important;
}

.button {
    display: block !important;
    background-color: #bbb !important;
}

button {
    width: 25% !important;
    height: 60px !important;
    font-size: 30px !important;
} */

.responsive-modal-btn{
    border-radius: 0!important;
    box-shadow: none!important;
    height: 38px!important;
    margin-top: 20px!important;
    width: 100%!important;
    background-color: #f15733!important;
    color: #fff!important;
    width: 100%;
}

.responsive-modal-nobtn{
    background-color: #fff !important;
    color: #f15733!important;
}