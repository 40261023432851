.wrapper {
    background-color: white;
    height: 100%;
    /* padding: 50px; */
    border-radius: 10px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
}

.report_wrapper_tab {
    /* padding-top: 50px; */
}

.label {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.select {
    width: 100%;
}

.table {
    /* height: 500px; */
    max-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    outline: 1px solid lightgray;
}

.header {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 10px;
    background-color: lightgrey;
}

.list {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
}


.report_icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
}


.logs_user {
    /* height: 40px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 15px 0px;
}

.notify_msg {
    display: flex;
    gap: 12px
}

.notify_msg .ant-avatar {
    background: #F7F7F7;
    color: black;
}

.notify_subhead {
    font-size: 10px;
    color: #B0B0B0;
    padding-top: 5px;
}