/* List */

/* List */

/* Add */
.add_emoloyee_wrapper {
    height: 100%;
    overflow-y: scroll;
    background-color: white;
    border-radius: 10px;
    padding-inline: 10px;
    /* box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.75); */
}

.form_title {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1.5px solid rgb(214, 214, 214);
}

/* Add */

.form_group_wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.input_group_container {
    background-color: rgb(245, 245, 245);
    padding: 5px;
}

.input_group {
    display: flex;
}

.add_vendor_btn {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
}

/* View */
.employee_view_btn {
    display: flex !important;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
    padding: 30px;
}