@media only screen and (max-width:1200px) {
    .tabSectionRes {
        align-items: unset;
        justify-content: unset;
    }

    .ant-tabs-tab-active>.ant-tabs-tab-btn {
        font-size: 12px;
        padding: 0px 10px;
    }

    .ant-tabs-tab-btn {
        font-size: 12px;
    }

    .changePasswordbtnOverLap {
        position: absolute;
        right: 0%;
        top: 0;
    }

    .changePasswordbtnOverLap1 {
        position: absolute;
        right: 0%;
        top: 0;
    }

    .report_icons {
        display: unset !important;
    }

    .ant-checkbox-group {
        display: unset !important;
    }

    .ant-checkbox-wrapper {
        margin-bottom: 15px;
    }

    .tabCenterPlace {
        width: auto;
        display: unset;
        align-items: center;
        justify-content: center;
    }
   
}

@media only screen and (max-width:768px) {
    .flexRemove {
        display: unset !important;
    }
    .input-new{
        width: 140px!important;
    }

    /* .new-time-picker{
        margin-left: 21px!important;
    } */
}

@media only screen and (max-width: 991px) {
    .changePasswordbtnOverLap {
        position: unset;
        float: right;
    }

    .changePasswordbtnOverLap1 {
        position: unset;
        float: right;
    }
    /* .new-time-picker{
        margin-left: 21px!important;
    } */
}

@media only screen and (max-width:1350px) {
    .new-time-picker{
        margin-left: 0px!important;
    }
.label-timepicker{
    margin-right: 0px!important;
}
}

