.dashboard_wrapper {
    /* overflow-y: scroll; */
    /* height: 100%; */
    /* width: 100%; */
    overflow-y: scroll;
}

.dashboard {
    height: 100%;
    width: 100%;
    min-height: 485px !important;
    min-width: 485px;
    max-height: 485px;
    max-width: 485px !important;
    margin: 10px;
    box-sizing: border-box !important;
    border: 1px solid #e6e6e6;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: relative !important;
}

.one {
    position: absolute;
    left: 0;
    top: 10%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    cursor: pointer;
}

.one>img,
.two>img,
.three>img,
.four>img {
    height: 115px;
    width: 115px;
}

.two {
    height: 115px;
    width: 115px;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* left: 0; */
    right: 0;
    top: 10%;
    border-radius: 50%;
    cursor: pointer;
}

.three {
    height: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 115px;
    position: absolute;
    /* left: 0; */
    right: 0;
    bottom: 10%;
    border-radius: 50%;
    cursor: pointer;
}

.four {
    height: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 115px;
    position: absolute;
    left: 0;
    bottom: 10%;
    border-radius: 50%;
    cursor: pointer;
}

.five {
    /* position: absolute; */

    border-radius: 50%;
    height: 202px;
    width: 201px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard_banner {
    width: 100%;

    /* width: 650px; */
    height: 268px;
}

.dashboard_active_table {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
}

.dashboard_active_table_top {}

.dashboard_active_table_bg {
    background-color: black;
    color: #ffffff;
    display: flex !important;
    padding: 10px;
    border-radius: 10px;
    justify-content: center;
}

.tableHead {
    font-size: 22px;
    color: #484848;
    font-weight: bold;
}