.wrapper {
    background-color: white;
    height: 100%;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
}

.report {
    overflow-y: scroll;
}

.report_wrapper_tab {
    padding-top: 50px;
}

.label {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.select {
    width: 100%;
}

.table {
    /* height: 500px; */
    max-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    outline: 1px solid lightgray;
}

.header {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 10px;
    background-color: lightgrey;
}

.list {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
}


.report_icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
}


.logs_user {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* padding: 10px; */
}

.account_label {
    width: 150px !important;
}

.input_size {
    width: 100px
}



/* List */

/* List */

/* Add */
.add_emoloyee_wrapper {
    height: 100%;
    overflow-y: scroll;
    background-color: white;
    border-radius: 10px;
    padding-inline: 10px;
    /* box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.75); */
}

.form_title {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1.5px solid rgb(214, 214, 214);
}

/* Add */

.form_group_wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.input_group_container {
    background-color: rgb(245, 245, 245);
    padding: 5px;
}

.input_group {
    display: flex;
}

.add_vendor_btn {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
}

/* View */
.employee_view_btn {
    display: flex !important;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
    padding: 30px;
}



.wrapper {
    background-color: white;
    height: 100%;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
}

.report_wrapper_tab {
    padding-top: 50px;
}

.label {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.select {
    width: 100%;
}

.table {
    /* height: 500px; */
    max-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    outline: 2px solid #EDEDED;
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 10px;
    background-color: #EDEDED;
}

.list {
    height: 100%;
    overflow: hidden;
    overflow-y: clip;
    padding: 15px 0px;
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
}


.report_icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
}


.logs_user {
    height: auto;
    border-bottom: 1px solid #d6d6d6 !important;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* padding: 10px; */
}

.tableTopLinks span svg {
    font-size: 20px;
    color: #f15733;
    margin-right: 5px;
}

.tableTopLinks span {
    text-decoration: underline;
    cursor: pointer;
}

.attendence {
    background: #D8D8D8 !important;
}