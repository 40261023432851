
.logo {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin-login {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.title{
    padding-top: 150px;
}