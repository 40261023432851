/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* @import url("https://fonts.googzleapis.com/css2?family=Sen:wght@400;700;800&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

/* @font-face {
    src: url('https://fonts.cdn.com/Avenir-Regular.ttf') format('truetype');
    font-family: 'Avenir';
    font-weight: 400;
    font-style: normal;
} */

:root {
    --red: #e22027;
    --white: #fff;
    --gray: #d3d3d3;
    --crumb: #7e7e7e;
    --sidebar: #22272f;
    --outlet: #f7f8f9;
    --primary: #f15733;
    --activeBg: #33383f;
    --text: #909397;
    --tabelHeader: #ededed;
    --seconday: #15CDA1;
    /* --tabelHeader : red; */
}



* {
    padding: 0;
    margin: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--gray) transparent;
    text-decoration: none;
    /* font-family: 'Avenir', sans-serif !important; */
    /* font-weight: 400; */
}

body {
    max-height: 100vh;
    max-width: 100vw;
    height: 100vh;
    width: 100vw;
}

a {
    color: inherit !important;
}

/* .d-none {
    pointer-events: none;
} */

.ant-layout {
    background: #F7F8F9 0% 0% no-repeat padding-box;
}

.ant-table-header {
    border-radius: 0px 0px 0 0 !important;
}

:where(.css-dev-only-do-not-override-1awgun7).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
    border-start-start-radius: 0px !important;
}

:where(.css-dev-only-do-not-override-1awgun7).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
    border-start-end-radius: 0px !important;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    /* border-radius: 100vh; */
}

.ant-upload-list-item-progress {
    display: none !important;
}

.text-area {
    height: 30px;
}

::-webkit-scrollbar-track {
    margin-block: 5px;
    margin-inline: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 100vw;
}

.sidebar_logo {
    height: 100%;
    width: 100%;

    align-items: center;
    justify-content: center;
    object-fit: cover;
    display: flex;
}

.sidebar_logo>img {
    border-radius: 50%;

}

.plus_outline {
    color: var(--primary);
    padding: 2px;
    border-radius: 4px;
    outline: 1px solid var(--primary);
}


.login {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.d-none {
    display: none !important;
}

.main-modal {
    color: var(--primary);
}

.add_outlet {
    background-color: white;
    border-radius: 10px;
    /* height: 100%; */
    /* overflow: hidden; */
    overflow-y: scroll;
    padding-inline: 20px;
    padding-bottom: 10px;
}

.ant-checkbox .ant-checkbox-inner:after {
    border-color: #ff815e !important;
    left: 5px;
}

.check_outline {
    color: var(--seconday);
    height: 14px !important;
}

.ant-checkbox+span {
    position: relative;
    top: 3px;
}

.icon_btn_add {
    outline: 1px solid #f15733;
    border-radius: 4px;
}

.ant-menu-item-active {
    background: #33383f !important;
    color: #ffff !important;
}

.ant-menu-item-selected {
    color: #fff !important;
    background: #33383f !important;
}

.ant-menu-item-active>svg {
    color: #f15733 !important;
}

.ant-menu-item-selected>svg {
    color: #f15733 !important;
    /* background: #33383F !important; */
}

.ant-radio-inner {
    background-color: transparent !important;
}

.ant-radio-inner::after {
    background-color: #f15733 !important;
}

.ant-tabs-tab-active {
    background-color: var(--primary) !important;
    border-radius: 6px !important;
}

.ant-menu-item-active>span>svg {
    color: var(--primary) !important;
}

.ant-tabs-tab-active>.ant-tabs-tab-btn {
    color: white !important;
}

.ant-menu-item-selected>span>svg {
    color: var(--primary);
}

.user_icon {
    display: flex !important;
    align-items: center;
    /* margin-left: 20px; */
    height: 100px !important;
    width: 96px !important;
    justify-content: center;
    /* font-size: 30px !important; */
}

.user_icon>img {
    height: 80px !important;
    width: 80px !important;
}

.outlet {
    height: 100%;
    width: 100%;
    display: flex;
    /* padding: 20px; */
    gap: 25px;
    flex-direction: column;
    max-height: 100%;
    /* overflow: hidden; */
    overflow-y: scroll;
    border-radius: 5px;
}

.ant-upload-select {
    border: none !important;
    background-color: transparent !important;
}

/* antd */

.sidebar {
    border-radius: 0px 15px 15px 0px !important;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--sidebar) !important;
    
} 

.menu-list li:hover{
    color: #fff !important;
} 
.menu-list li{
    color: #909397 !important;
}
.sidebar .ant-menu-root{
    color: #909397 !important;
}

.butn {
    position: absolute;
    top: 50%;
    right: -9px;
    z-index: 1;
    border: none;

    /* outline: 5px solid inherit; */
    outline: none;
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    /* background-color: #d7d7d7; */
    box-shadow: 10px 10px 22px -5px rgba(0, 0, 0, 0.61);
    -webkit-box-shadow: 10px 10px 22px -5px rgba(0, 0, 0, 0.61);
    -moz-box-shadow: 10px 10px 22px -5px rgba(0, 0, 0, 0.61);
}

.ant-menu-root {
    background-color: var(--sidebar) !important;
    color: var(--text) !important;
}

.ant-table-thead>tr>.ant-table-cell.age {
    background-color: red !important;
}

.full_width {
    width: 100% !important;
}

.breadcrumb_active {
    color: var(--primary);
}

.ant-checkbox-inner {
    /* background-color: white !important; */
    width: 22px !important;
    height: 22px !important;
}

::where(.css-dev-only-do-not-override-1awgun7).ant-checkbox .ant-checkbox-inner:after {
    border-color: #f15733 !important;
    left: 6px;
    top: 10px;
}

::where(.css-dev-only-do-not-override-1awgun7).ant-checkbox+span {
    margin-top: 3px;
    margin-left: 10px;
}

/* outlet */

.outlet_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 8px;
    justify-content: center;
    background: #ededed;
    border-radius: 15px 15px 0 0;
    width: 325px;
    margin-left: 30px;
}

.outlet_top_search_wrapper input {
    border-color: #A1A1A1;
    height: 40px;
}

.outlet_top_search_wrapper button {
    border-color: #A1A1A1;
    border-left: 0px;
    height: 40px !important;
}

.outlet_top_search_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.outlet_top_search_input {
    width: 250px;
}

.outlet_top_search_filter_icon {
    font-size: 26px;
    cursor: pointer;
}

.outlet_top_search_filter_checkbox_group {
    display: flex;
    background: #ededed;
    padding: 10px;
    gap: 25px;
}

.outlet_table {
    height: 100%;
    width: 100%;
}

.outlet_table .ant-table-cell {
    border-bottom: 0px solid #f0f0f0 !important;
    border-right: 1px solid #CFCFCF;
}

.outlet_table th {
    border-right: 0px solid #CFCFCF !important;
}

.outlet_table .ant-table-cell:last-child {
    border-right: 0px solid #CFCFCF !important;
}

/* .ant-tabs-content-holder{
    display: none;
} */

.ant-tabs-ink-bar-animated {
    display: none;
}

/* outlet */



.ant-form-item-label {
    position: relative;
}

.text_area_btn {
    position: absolute !important;
    right: 15px;
    color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    border-radius: 5px !important;
    top: 0;
    cursor: pointer;
}

/* .logo {
    /* height: 100%; */
/* width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column; 
} */


.sidebar_profile {
    display: flex;
    gap: 6px;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-inline: 16px;
    /* height: 84px; */
    border: 1px solid lightgray;
    border-radius: 6px;
    padding-block: 10px;
}


.sidebar_profile_logout {
    position: absolute;
    left: 15%;
    bottom: 2%;
    display: flex;
    gap: 6px;
    align-items: center;
    width: 100%;
    cursor: pointer;
    /* height: 84px; */
    border-radius: 6px;
    padding-block: 10px;
}

/* .logout_btn {} */

.justify_center {
    justify-content: center;
}

.upload {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color: var(--primary);
}

.upload_add_btn {
    border: 1px solid var(--primary);
    border-radius: 5px;
    height: 20px;
    display: flex;
    align-items: center;

}


.loader_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}



.check_out_status {
    color: var(--seconday);
}

.check_in_status {
    color: var(--seconday);
}

.ant-table-thead>tr>.early {
    background-color: var(--seconday) !important;
    color: white !important;
}

.ant-table-thead>tr>.late {
    background-color: #CAD127 !important;
    color: white !important;
}

.ant-table-thead>tr>.off {
    background-color: #F15733 !important;
    color: white !important;
}

.ant-input-number-handler-wrap {
    display: none !important;
}



.event-none {
    pointer-events: none;
}

.saveButtonPrimary {
    border: 1px solid #15CDA1 !important;
    background: #15CDA1 !important;
    border-radius: 10px !important;
    height: 40px !important;
    padding: 4px 30px !important;
}

.saveButtonPrimary1 {
    border: 1px solid #15CDA1 !important;
    background: white !important;
    border-radius: 10px !important;
    height: 40px !important;
    padding: 4px 30px !important;
    color:#15CDA1!important;
}
.saveButtonPrimary:hover {
    background: #15CDA1 !important;
}

.saveButtonPrimary span svg {
    border: 1px solid white;
    border-radius: 2px;
    padding: 2px;
}

.cancelBtn {
    border: 1px solid #15CDA1 !important;
    border-radius: 10px !important;
    color: #15CDA1 !important;
    height: 40px !important;
    padding: 4px 30px !important;
    background: transparent !important;
}

.cancelBtn:hover {
    border-color: #15CDA1 !important;
    color: #15CDA1 !important;
}

.inneraddbuttonInput {
    width: 22px !important;
    border-radius: 5px !important;
    font-size: 9px !important;
    height: 22px !important;
}

/* .ant-tabs-content-holder {
    display: none;
} */


.sidebarnameArrow {
    position: absolute;
    right: 15%;
    background: white;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: black;
    text-align: center;
    padding-top: 3px;
    margin-top: 8px;
}

.greyColor {
    color: #909397;
}

.tables-constant {
    width: 144px;
}

.upload button:first-child {
    border: 1px solid #A1A1A1;
    border-radius: 5px;
    color: #302F46;
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 10px;
}

/* .ant-tabs-tab {
    border-bottom: 1px solid #d6d6d6 !important;
    margin-top: 0px !important;
} */

.ant-upload-list-item {
    height: 100% !important;
    border: 1px solid #f15733 !important;
    color: #f15733;
    border-radius: 50px !important;
    padding: 5px !important;
}

.max_width {
    max-width: 300px;
}

.ant-upload-list-item-thumbnail {
    display: none;
}

.picture button .anticon-delete {
    color: #f15733 !important;
}

.ant-input-group-addon {
    background-color: transparent !important;
}

.wrapper {
    padding: 30px !important;
    height: auto !important;
}

.labelCustomSize label {
    width: 145px;
}

.labelCustomSize #check_in_time,
#check_out_time {
    width: 110px;
}


.ant-checkbox-wrapper-checked .ant-checkbox-inner {
    /* color: var(--primary) !important; */
    background-color: transparent !important;
}

:where(.css-1xcdac7).ant-divider-horizontal {
    margin: 0px !important;
}

.user_icon1 {
    margin-bottom: 30px;
    margin-left: 50px;
    /* width: 100% !important; */
}



/* .tabSectionRes {
    align-items: center;
    justify-content: center;
} */

.ant-divider {
    margin: 10px 0px !important;
}

.ant-form-item .ant-form-item-label>label::after {
    content: "" !important
}

.changePasswordbtnOverLap {
    position: absolute;
    right: 30%;
    top: 0;
}

.changePasswordbtnOverLap1 {
    position: absolute;
    right: 8%;
    top: 0;
}

.tabCenterPlace {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.patternformatDesign:focus-visible {
    border-width: 1px;
    border-style: solid;
    border-color: #ff815e;
    box-shadow: 0 0 0 2px rgba(255, 88, 5, 0.06);
    border-inline-end-width: 1px;
}

.patternformatDesign:focus {
    border-width: 1px;
    border-style: solid;
    border-color: #ff815e;
    box-shadow: 0 0 0 2px rgba(255, 88, 5, 0.06);
    border-inline-end-width: 1px;
}

.patternformatDesign {
    width: 300px;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: Sen;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
    outline: none !important;

}

.custom-span .ant-form-item-control-input-content {
    position: relative;
}

.custom-span .ant-form-item-control-input-content::after {
    position: absolute;
    content: "/Day";
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0px 10px 0px;
    background-color: #ededed;
    border-top-right-radius: 6px;
    border-right: #e22027;
    /* bo: 1px solid red; */
    border-bottom-right-radius: 6px;
    line-height: 32px;
    text-align: center;
    columns: rgba(0, 0, 0, 0.88);
    font-size: 12px;
}

.custom-span-1 .ant-form-item-control-input-content {
    position: relative;
}

.custom-span-1 .ant-form-item-control-input-content::after {
    position: absolute;
    content: "/Week";
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0px 10px 0px;
    background-color: #ededed;
    border-top-right-radius: 6px;
    border-right: #e22027;
    /* bo: 1px solid red; */
    border-bottom-right-radius: 6px;
    line-height: 32px;
    text-align: center;
    columns: rgba(0, 0, 0, 0.88);
    font-size: 12px;
}

.custom-span-2 .ant-form-item-control-input-content {
    position: relative;
}



.custom-span-2 .ant-form-item-control-input-content::after {
    position: absolute;
    content: "/Bi-Weekly";
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0px 10px 0px;
    background-color: #ededed;
    border-top-right-radius: 6px;
    border-right: #e22027;
    /* bo: 1px solid red; */
    border-bottom-right-radius: 6px;
    line-height: 32px;
    text-align: center;
    columns: rgba(0, 0, 0, 0.88);
    font-size: 12px;
}

.custom-span-3 .ant-form-item-control-input-content {
    position: relative;
}

.custom-span-3 .ant-form-item-control-input-content::after {
    position: absolute;
    content: "/Month";
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0px 10px 0px;
    background-color: #ededed;
    border-top-right-radius: 6px;
    border-right: #e22027;
    /* bo: 1px solid red; */
    border-bottom-right-radius: 6px;
    line-height: 32px;
    text-align: center;
    columns: rgba(0, 0, 0, 0.88);
    font-size: 12px;
}

.custom-span-prefix .ant-form-item-control-input-content {
    position: relative;
}

.custom-span-prefix .ant-form-item-control-input-content::after {
    position: absolute;
    content: "$";
    width: 40px;
    left: 0;
    top: 0;
    bottom: 0;
    /* padding: 0px 10px 0px; */
    background-color: #ededed;
    border-top-left-radius: 6px;
    border-left: #e22027;
    /* bo: 1px solid red; */
    border-bottom-left-radius: 6px;
    line-height: 32px;
    text-align: center;
    columns: rgba(0, 0, 0, 0.88);
    font-size: 12px;
}

.poppins_regular-18 {
    font-size: 18px;
    font-weight: 400;
}

.custom-span-prefix .dollar {
    padding-left: 45px;
}

.view_doc .ant-upload-list-item-actions {
    display: none;
}

.ant-picker-panel-layout {
    flex-direction: row-reverse !important;
}

.select_width {
    width: 285px !important;
}

.table_check_box {
    display: flex !important;
    align-items: center !important;
    padding: 10px !important;
    overflow-x: scroll !important;
    background: #ededed !important;
    gap: 25px !important;
}

.table_check_box .ant-checkbox-group {
    display: flex !important;
    gap: 15px !important;
}

.table_check_box .ant-checkbox+span{
    top:0px !important;
}

.Toastify__toast-container--top-center {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.user_icon_name {
    display: flex !important;
    height: 120px !important;
    text-align: center;
    font-size: 17px !important;
    font-weight: 500;
    white-space: nowrap;
    flex-direction: column;
}

.user_iconwithname {
    height: 100px !important;
    width: 100px !important;
}

.Toastify__toast-body {
    display: flex !important;
}

.demo {
    background-color: gray !important;
}

.ant-message {
    z-index: 9999 !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 8px !important;
}

.ant-message-notice {
    padding: 8px;
    text-align: center;
}

.ant-message .ant-message-notice .ant-message-notice-content {
    display: inline-block;
    padding: 9px 12px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: all;
}

.star {
    color: red;
}

.setting_with_prefix {
    width: 190px !important;
}

.ant-table.ant-table-small tfoot>tr>td {
    padding: 5px 5px !important;
}

.ant-menu-item-icon{
    width: 15px !important;
}

.svg-img{
    width: 15px !important;
}
/* 
td {
    padding: 6px 6px !important;
} */

/* :where(.css-dev-only-do-not-override-co4p3n).ant-table-wrapper .ant-table.ant-table-small .ant-table-title,
:where(.css-dev-only-do-not-override-co4p3n).ant-table-wrapper .ant-table.ant-table-small .ant-table-footer,
:where(.css-dev-only-do-not-override-co4p3n).ant-table-wrapper .ant-table.ant-table-small .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-co4p3n).ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>th,
:where(.css-dev-only-do-not-override-co4p3n).ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>td,
:where(.css-dev-only-do-not-override-co4p3n).ant-table-wrapper .ant-table.ant-table-small tfoot>tr>th,
:where(.css-dev-only-do-not-override-co4p3n).ant-table-wrapper .ant-table.ant-table-small tfoot>tr>td {
    padding: 5px 5px !important;
}


:where(.css-dev-only-do-not-override-co4p3n).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-co4p3n).ant-table-wrapper .ant-table-tbody>tr>th,
:where(.css-dev-only-do-not-override-co4p3n).ant-table-wrapper .ant-table-tbody>tr>td,
:where(.css-dev-only-do-not-override-co4p3n).ant-table-wrapper tfoot>tr>th,
:where(.css-dev-only-do-not-override-co4p3n).ant-table-wrapper tfoot>tr>td {
    padding: 5px 5px !important;
} */


/* .ant-table-tbody>tr>td {
    padding: 6px 6px !important;
} */

/* .ant-message-custom-content {
    font-size: 20px !important;
    display: flex !important;
    align-items: center;
    padding: 6px;
}

.anticon-check-circle,
.anticon-close-circle {
    font-size: 20px !important;
} */

.toaster-modal .ant-modal-content{
    border-radius: 15px !important;;
    padding: 0px !important;
}
.toaster-modal .ant-modal-content button>span>span>svg{
    color: white !important;
}
.toaster-modal .ant-modal-content button{
    top: 4px !important;
    right: 5px !important;

}

.ant-modal-close {
    position: absolute;
    top: 17px;
    inset-inline-end: 17px;
    z-index: 1010;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 600;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border-radius: 4px;
    width: 22px;
    height: 22px;
    border: 0;
    outline: 0;
    cursor: pointer;
    right: 10px !important;
    transition: color 0.2s,background-color 0.2s;
}

/* Master */

.master-tab .ant-tabs-tab-active {
    background-color: transparent !important;
    border-radius: none !important;
}

.master-tab .ant-tabs-tab-active>.ant-tabs-tab-btn {
    color: red !important;
}

.master-tab .ant-tabs-ink-bar-animated {
    display: initial;
}

.react-responsive-modal-modal{
    padding: 0 !important;
    /* border-radius: 8px !important;
    width: 400px; */
}

.react-responsive-modal-closeButton{
    display: none !important;
}

.res-modal{
    width: 400px;
    background-color: #e74c3c !important;
	color: white !important;
    text-align: center !important;
}

.res-modal1{
    width: 600px;
    background-color: #e74c3c !important;
	color: white !important;
    text-align: center !important;
}


.res-modal-title{
    color: #fff;
    font-size: 18px;
    padding: 15px;
    line-height: 1.5;
    font-weight: 600;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

.res-modal-desc{
    padding: 15px 25px;
    background-color: #fffffd;
    text-align: center;
}

.res-modal-btn-group{
    display: flex;
    justify-content: space-around;
    gap: 30px !important;
}

.res-modal-question{
    letter-spacing: 0 !important;
    font-size: 16px;
    font-weight: 700;
    text-align: 'center';
    margin-top: 15px;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}


.res-modal-question1{
    letter-spacing: 0 !important;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin-top: 15px;
    margin-right: 20px;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

.res-toaster{
    width: 300px;
}


@keyframes customEnterOverlayAnimation {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes customLeaveOverlayAnimation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
}
  
@keyframes customEnterModalAnimation {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(1);
    }
}

@keyframes customLeaveModalAnimation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.2);
    }
}

.upload-center{
    text-align: center;
}

.upload-center .ant-upload-list {
    justify-content: center;
    display: flex;
}



.responsive-modal1{
    width: 80%!important; 
    max-width: 800px; 
}
/* 
.new-timepicker>input{
width:71px!important
} */