.layout{
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
}

.content{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}