.layout_content {
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    padding: 20px;
    gap: 5px;
}

.layout_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 146px;
}

.layout_top_left_title {
    display: flex;
    align-items: center;
    gap: 20px;
}

.layout_top_right {
    display: flex;
    gap: 10px;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
    position: absolute;
    right: 2%;
    top: 0;
    margin-top: 1%;
}

.layout_top_right_avatar {
    height: 80px !important;
    width: 80px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.layout_top_right_avatar>svg {
    font-size: 40px;
    color: gray;
}

.layout_add_btn {
    margin-bottom: 10px;
    display: flex !important;
    border-radius: 10px !important;
    padding: 20px !important;
    align-items: center;
    gap: 10px;
    background: #f7f8f9 !important;
}

.layout_children {
    height: 100%;
    overflow-y: scroll;
    /* overflow: hidden; */
    padding: 20px 0px;
    border-radius: 10px;
}